import { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../FirebaseConfig';
import { useLocation } from 'react-router-dom';
import './Reviews.css';
import { useNavigate } from 'react-router-dom';
import VerifyEmailMsg from '../Components/VerifyEmailMsg';

export function ReviewCard({ review }) {
  const calculateAverageScore = (keys) => {
    const totalScore = keys.reduce((acc, key) => acc + (review[key]?.score || 0), 0);
    return (totalScore / keys.length)/120 * 100;
  };
  // Define the keys and titles for each category
  const categories = [
    {
      keys: ['landlord', 'agency', 'contract', 'utilitiesBill'],
      title: 'Lease Insights',
    },
    {
      keys: ['structuralIntegrity', 'adjacentSoundproofing', 'verticleSoundproofing', 'windowSoundproofing', 'apartmentNoise'],
      title: 'Interior Quality',
    },
    {
      keys: ['bed', 'ac', 'heating', 'dishwasher', 'microwave', 'oven', 'stovetop', 'refrigerator'],
      title: 'Amenities',
    },
    {
      keys: ['safety', 'cityConnection', 'recreationalArea', 'streetParking'],
      title: 'Area Information',
    },
  ];

  // Map over each category to render a DropdownItem with details
  const categoryElements = categories.map((category) => {
    const averageScore = calculateAverageScore(category.keys);
    const details = category.keys.map((key) => ({
      name: key,
      value: review[key]?.score ? `${(review[key].score / 120 * 100).toFixed(2)}%` : 'N/A', // Assuming score is out of 120
    }));

    return (
      <DropdownItem
        key={category.title}
        title={`${category.title} Score`}
        score={averageScore}
        details={details}
      />
    );
  });

  return (
    <div className='review-card'>
      {categoryElements}
    </div>
  );
}

const DropdownItem = ({ title, score, details }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
      <div className="dropdown-item">
          <div className="dropdown-header" onClick={toggleOpen}>
              <span className="descriptor">{title}:</span>
              <span className="info">{score.toFixed(2)}</span>
          </div>
          {isOpen && (
              <div className="dropdown-content">
                  {details.map((detail, index) => (
                      <p key={index}>
                          {detail.name}: {detail.value}
                      </p>
                  ))}
              </div>
          )}
      </div>
  );
};

function Reviews({ user }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const address = queryParams.get('address');
  const latitude = queryParams.get('latitude').toString().replace(".", "_").replace("-", "m");
  const longitude = queryParams.get('longitude').toString().replace(".", "_").replace("-", "m");
  const [reviews, setReviews] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user.currentUser) {
      navigate(`/login?from=${"Reviews" + location.search}`);
    }
  }, [navigate, user.currentUser]);

  useEffect(() => {
    const dbRef = ref(database, `/Review/${longitude}A${latitude}/`);
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const reviewsArray = Object.values(data).sort((a, b) => new Date(a.startdate) - new Date(b.startdate));
        setReviews(reviewsArray);
      }
    });
  }, [latitude, longitude]);

  return (
    <>
    {user.currentUser ?
      <>
      {user.currentUser?.emailVerified ?
        <div className='review-main'>
          <h2>Reviews for: {address}</h2>
          <ul className='card-container'>
            {reviews.map((review, index) => (
              <ReviewCard key={index} review={review} />
            ))}
          </ul>
        </div>
        :
        <VerifyEmailMsg user={user} />
      }</>:
      <div> d </div>
      }
    </>
  );
}

export default Reviews;

