import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import { getDatabase } from "firebase/database"

const firebaseConfig = {
    apiKey: "AIzaSyAt3ChZsf56JEIggbUN5GwSTqzInmCUIko",
    authDomain: "oikolex.com",
    databaseURL: "https://rent-1111-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "rent-1111",
    storageBucket: "rent-1111.appspot.com",
    messagingSenderId: "984831872746",
    appId: "1:984831872746:web:e354e99bc3e618922ad193",
    measurementId: "G-VXMVDFLKGR"
};

const app = firebase.initializeApp(firebaseConfig);
export const auth = app.auth();
//export const firestore = getFirestore(app);
export const database = getDatabase(app);

export const db = firebase.firestore();