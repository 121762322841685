import { useState, useEffect } from 'react';
import { getDatabase, onValue, ref, remove, get, child, update, set } from "firebase/database";
import { database } from '../FirebaseConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import { getAuth, signOut } from 'firebase/auth';
import { getApp } from "firebase/app"; // If needed, import your Firebase app
import VerifyEmailMsg from '../Components/VerifyEmailMsg';
import { getFunctions, httpsCallable } from "firebase/functions";
import Loading2 from '../Components/Loading2';
import './Profile.css';

export function ReviewCard({ children, reviewId, parentKey, onDelete }) {
  return (
    <div className='review-card'>
      <h3>Review</h3>
      {children}
      <button onClick={() => onDelete(reviewId, parentKey)}>Delete Review</button>
    </div>
  );
}

function Profile({user}) {
    const location = useLocation();
    const userUid = user.currentUser?.uid; // Update this based on your URL parameter
    const [loading, setLoading] = useState(true);
    const [previousSubmissions, setPreviousSubmissions] = useState([]);
    const navigate = useNavigate(); // Get the navigate function
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        if (!user.currentUser) {
          navigate(`/login?from=${"Profile" + location.search}`); // Redirect to login if user is not logged in
        }
      }, [user.currentUser, navigate]);

    const auth = getAuth();

        // const database = getDatabase();
        const [submissionKeys, setSubmissionKeys] = useState([]);

        useEffect(() => {
          const submissionsRef = ref(database, `Users/${userUid}/Submissions`);
          
          onValue(submissionsRef, (snapshot) => {
            if (snapshot.exists()) {
              const submissionsData = snapshot.val();
              const combinedDataPromises = Object.entries(submissionsData).map(([submissionKey, { parentKey, reviewId }]) => {
                return get(ref(database, `Review/${parentKey}/${reviewId}`)).then((reviewSnap) => {
                  if (reviewSnap.exists()) {
                    return {
                      ...reviewSnap.val(), // Spread review details
                      submissionKey, // Add submissionKey for easier deletion handling
                      reviewId, // Include reviewId in case it's not part of the review details
                      parentKey, // Include parentKey for deletion logic
                    };
                  }
                  return null;
                });
              });
        
              Promise.all(combinedDataPromises).then((reviews) => {
                const filteredReviews = reviews.filter((review) => review !== null); // Remove any null entries
                setPreviousSubmissions(filteredReviews); // Update state with combined review data
                setLoading(false);
              });
            }else{
              setLoading(false);
            }
          });
        }, [userUid, previousSubmissions]);
    
    function popUpDeleteAccountConfirmation() {
      const confirmation = window.confirm('Are you sure you want to delete your account?');
      if (confirmation) {
        handleDeleteAccount();
      }
    }

    async function handleDeleteAccount() {
      const app = getApp(); // Ensure you're using the correct app if needed
      const functions = getFunctions(app, 'europe-west1'); // Get Firebase functions
      const deleteUserAccount = httpsCallable(functions, 'deleteUserAccount'); // Call the deleteUserAccount function
    
      document.getElementById('deleteButton').disabled = true;
      setDeleting(true); // Set state to show deletion in progress
    
      try {
        // Call cloud function to delete the user account
        const result = await deleteUserAccount();
        console.log(result.data.message);
        
        // After deletion, log the user out locally
        await signOut(auth);
        
        // Redirect to the login page after logout
        navigate('/login');
      } catch (error) {
        console.error("Error deleting account:", error);
        setDeleting(false);
      }
    }
    function handleDeleteReviewConfirmation(){
      const confirmation = window.confirm('Are you sure you want to delete this review?');
      if (confirmation) {
        handleDeleteReview();
      }
    }
    async function handleDeleteReview(reviewId, parentKey) {
      const app = getApp(); // Ensure you're using the correct app if needed
      const functions = getFunctions(app, 'europe-west1');
      const deleteReviewFunction = httpsCallable(functions, 'deleteReview');
  
      const data = {
        reviewId: `${reviewId}`, 
        parentKey: `${parentKey}`};
      try {
          const result = await deleteReviewFunction(data);
          console.log(result.data.message);
      } catch (error) {
          console.error("Error deleting review:", error);
      }
    }

    return (
      <>
        {deleting ? 
        <Loading2>
          <h2 className='delete-text'>Deleting account&nbsp;
            <span className="span1">.</span>
            <span className="span2">.</span>
            <span className="span3">.</span>
          </h2>
        </Loading2> 
        :
        <>
        <div className='review-main'>
            <h2>Reviews</h2>
            {loading ? <Loading2/> :
            <ul className='card-container'>
            {previousSubmissions.map((review, index) => (
                  <ReviewCard
                    key={index}
                    reviewId={review.reviewId}
                    parentKey={review.parentKey}
                    onDelete={() => handleDeleteReviewConfirmation()} // Pass userUid here
                  >
                  <ul>
                    <li>
                      <p> <span className='descriptor'>Tenancy:</span> <span className='info'>{review.personalInfo.startdate} - {review.personalInfo.enddate}, {review.daysDifference} days</span></p>
                    </li>
                    <li>
                      <p> <span className='descriptor'>Address:</span> <span className='info'>{`${review.personalInfo.houseNumber}, ${review.personalInfo.road}, ${review.personalInfo.city}`} </span></p>
                    </li>
                    {/* Assuming you add a delete button within ReviewCard itself as per previous discussion */}
                    {/* Otherwise, add a button here and handle deletion */}
                  </ul>
                </ReviewCard>
            ))}
            </ul>
            }
            {user.currentUser?.emailVerified | loading ? null : <VerifyEmailMsg user={user}/>}
        </div>
        <div className='profile-main'>
            <h2>Delete Account?</h2>
            <button id="deleteButton" onClick={popUpDeleteAccountConfirmation}>Delete Account</button>
        </div>
        </>
        }
      </>
    );
  }
  
export default Profile;
