import React from 'react';
import firebase from 'firebase/compat/app';
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithRedirect, getRedirectResult, setPersistence, browserLocalPersistence  } from "firebase/auth";
import googleLogo from './img/google-logo.png';
import './SignInWithGoogle.css';
import { useNavigate } from 'react-router-dom';

export default function SignInWithGoogle({redirectPath}) {
  const navigate = useNavigate();
  
  const handleSignInWithGoogle = async () => {
    const auth = getAuth(); // Initialize Firebase Auth
    
    try {
      // Set persistence to LOCAL to keep the user logged in after page refresh or reopening the browser
      await setPersistence(auth, browserLocalPersistence);
  
      // Create a new GoogleAuthProvider instance
      const provider = new GoogleAuthProvider();
  
      // Sign in the user with a Google pop-up
      const userCredential = await signInWithPopup(auth, provider);
  
      // Get the signed-in user's info
      const user = userCredential.user;
      console.log('User signed in with Google:', user);
  
      // Redirect to the desired path after login (ensure redirectPath is defined)
      navigate("../"+ redirectPath);
      
    } catch (error) {
      // Handle errors (e.g., if the user closes the Google login popup)
      console.error('Error during Google login:', error.message);
    }
  };

  return (
    <div>
      <button className="google-sign-in-button" onClick={handleSignInWithGoogle}>
        <div className="google-logo">
          <img src={googleLogo} alt="Google Logo" className="google-logo-image" />
        </div>
        Sign In with Google
      </button>
    </div>
  );
}
