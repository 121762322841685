import React from 'react';
// import firebase from 'firebase/compat/app';
import {useNavigate, useLocation} from 'react-router-dom';

export default function SignOut({user}){
  const navigate = useNavigate();
  const location = useLocation();

  const handleSignOut = () => {
    user.signOut()
      .then(() => {
        // Sign-out successful.
        document.cookie = 'session=; Max-Age=0; path=/login';
        navigate(`/login?from=${location.pathname.replace('/', "") + location.search}`);
      })
      .catch((error) => {
        // An error happened.
        console.error('Error signing out:', error);
      });
  };
    return user.currentUser && (
      <button className="btn btn-dark" onClick={handleSignOut}>Sign Out</button>
    )
}