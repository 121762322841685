import React, { useState, useEffect } from 'react';
import { applyActionCode, getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { Button } from 'react-bootstrap';
import SignInWithGoogle from './SignInWithGoogle';
import './Sign.css';

import { useNavigate, useLocation } from 'react-router-dom';

export default function SignIn({ toggleFormSignIn, toggleCardFlip, isCardFlipped, redirectPath, user }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const [currentUser, setCurrentUser] = useState(null); // Store the current user
  const navigate = useNavigate(); // Get the navigate function
  
  // const navigate = useNavigate(); // Get the navigate function

  const handleSubmit = async (event) => {
    //const auth = getAuth(); // Initialize Firebase Auth
    const auth = user;

    event.preventDefault();

    try {
      // Set persistence to LOCAL
      await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      // await setPersistence(auth, browserLocalPersistence);
      
      // Sign in the user after persistence is set
      const userCredential = await auth.signInWithEmailAndPassword(email, password);

      // const userCredential = await signInWithEmailAndPassword(email, password);
      console.log('User signed in:', userCredential.user);
      toggleFormSignIn(); // Toggle sign-in form after successful login
      navigate("../" + redirectPath);
    } catch (error) {
      // Set error message in case of any issues
      setError(error.message);
      console.error('Error signing in:', error.message);
    }
  };

  const db = firebase.firestore();
  // In your email verification page
  useEffect(() => {
    user.onAuthStateChanged(async (currentUser) => {
      if (currentUser && !verificationEmailSent) {
        await db.collection('users').doc(currentUser.uid).set({country: "United Kingdom"});
      }
    });
  }, []);

  const handleResetPassword = async () => {
    if (!email) {
      setError("Please enter your email address to reset your password.");
      return;
    }

    try {
      await user.sendPasswordResetEmail(email);
      alert("Password reset email sent! Please check your inbox.");
    } catch (error) {
      setError(error.message);
    }
  };

  return(
    <>
    {toggleFormSignIn &&
    <div className='sign-in-container'>
      <div className='sign-card-body-title'>
        <h2>Sign In</h2>
      </div>
      <div className='sign-card-body-input'>
        {!currentUser && 
        <div>
          <form onSubmit={handleSubmit} className='sign-form'>
            <div className='form-group'>
              <label htmlFor='email' className='sign-form-text'>Email</label>
              <input
                className='form-control'
                type='email'
                name='email'
                id='email'
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='password' className='sign-form-text'>Password</label>
              <input
                className='form-control'
                type='password'
                name='password'
                id='password'
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div className='sign-card-body-input-submit'>
              <Button type='submit'>Sign In</Button>
            </div>
            <button type="button" onClick={handleResetPassword} className="btn btn-link">
              Forgot Password?
            </button>
          </form>
          {error && (
              <div className='alert alert-danger mt-3' role='alert'>
                {/* {error && error.includes("Firebase:") ? error.split("Firebase:")[1].trim() : error} */}
                Email or password is incorrect. Please try again.
              </div>            
          )}        
        </div>
        }
        <SignInWithGoogle redirectPath={redirectPath}/>
        <p className='revert-sign'>
        Don't have an account?{' '}
        { !isCardFlipped ?
        <a type="button" onClick={toggleCardFlip} className='link'>Sign Up</a>
        :
        <a disabled type="button" className='link'>Sign Up</a>
        }
        </p>
      </div>
    </div>}</>
  )
}