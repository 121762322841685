import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import './Sign.css';
import VerifyEmailMsg from '../Components/VerifyEmailMsg';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

export default function SignUp({toggleCardFlip, toggleFormSignUp, isCardFlipped, user}){  
    const [createEmail, setCreateEmail] = useState('');
    const [createPassword, setCreatePassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState(''); // State for confirming password
    const [createError, setCreateError] = useState('');
    const [name, setName] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('United Kingdom');
    const [verificationEmailSent, setVerificationEmailSent] = useState(false);

    const db = firebase.firestore();
    const navigate = useNavigate(); // Get the navigate function

    if (user.currentUser){
      navigate("/");
    }
    const handleCreateAccount = async (event) => {
        event.preventDefault();
        // setLoading(true);
        if (createPassword !== confirmPassword) {
          setCreateError("Passwords do not match."); // Set error if passwords don't match
          return; // Stop the function if the passwords don't match
        }
        try {
          // Create the user account
          const userCredential = await user.createUserWithEmailAndPassword(createEmail, createPassword);
          const userc = userCredential.user;
          // Update the user document with the selected country
          await db.collection('users').doc(userc.uid).set({
            country: selectedCountry, // Add the selected country to the user document
          });
          // Send verification email and close the modal
          if(!verificationEmailSent){
            try {
              await userc.sendEmailVerification({
                url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
              });
              // setLoading(false);
              setVerificationEmailSent(true)
            } catch (error) {
              console.error('Error sending email verification:', error);
              // setLoading(false);
            }
          }
        } catch (error) {
          setCreateError(error.message);
        }
      };
    return(
      <>
      {user.currentUser ? <div><VerifyEmailMsg user = {user}/></div> :
      <div className='sign-up-container'>
          <div className='sign-card-body-title'>
            {/* <Button className='close' onClick={toggleFormSignUp}>x</Button> */}
            <h2>Sign Up</h2>
          </div>
          <div className='sign-card-body-input'>
          <form onSubmit={handleCreateAccount} className='sign-form'>
            <div className='form-group'>
              <label htmlFor='createEmail' className='sign-form-text'>Email</label>
              <input
                type='email'
                className='form-control'
                id='createEmail'
                aria-describedby='emailHelp'
                placeholder='Enter email'
                value={createEmail}
                onChange={({ target }) => setCreateEmail(target.value)}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='createPassword' className='sign-form-text'>Password</label>
              <input
                type='password'
                className='form-control'
                id='createPassword'
                placeholder='Password'
                value={createPassword}
                onChange={({ target }) => setCreatePassword(target.value)}
              />
            </div>
            <div className='form-group'> 
            Password must be at least 6 characters long
            </div>
            <div className='form-group'>
              <label htmlFor='confirmPassword' className='sign-form-text'>Confirm Password</label>
              <input
                className='form-control'
                type='password'
                name='confirmPassword'
                id='confirmPassword'
                onChange={e => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='name' className='sign-form-text'>Name</label>
              <input
                type='text'
                className='form-control'
                id='name'
                placeholder='Name'
                value={name}
                onChange={({ target }) => setName(target.value)}
              />
            </div>
            {/* <CountrySelection selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}/> */}
            <button type='submit' className='btn btn-primary'>
              Create account
            </button>
            {/* <Alert variant='danger' className='mt-3'>
              {createError && createError.includes("Firebase:") ? createError.split("Firebase:")[1].trim() : createError}
            </Alert> */}
            {createError && (
              <div className='alert alert-danger mt-3' role='alert'>
                {createError && createError.includes("Firebase:") ? createError.split("Firebase:")[1].trim() : createError}
              </div>            
            )}
          </form>
          <p className='revert-sign'>
          Already have an account?{' '}
          { isCardFlipped ?
          <a type="button" onClick={toggleCardFlip} className='link'>Sign In</a>
          :
          <a type="button" disabled className='link'>Sign In</a>
          }
          </p>
          </div>
      </div>
      }      
      </>)       
    
}