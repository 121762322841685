import React from 'react';

function LoadingIndicator() {
  return (
    <div className="loading-indicator">
      <p>Loading...</p>
      {/* You can add a loading animation or spinner here */}
    </div>
  );
}

export default LoadingIndicator;
