import React, { useState, useEffect } from 'react';
import SignInUp from '../Auth/SignInUp';
import SignOut from '../Auth/SignOut';
// import firebase from 'firebase/compat/app';
// import { useAuthState } from 'react-firebase-hooks/auth';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import './Navbar.css';

export default function Navbar({user}) {
    // const [user] = useAuthState(firebase.auth());
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    // console.log("user: ", user, user.currentUser);
    return (
        <>
            <nav className="navbar navbar-expand navbar-dark bg-dark">
                <div className="left-nav">
                    <Link to="/" className="navbar-brand">
                        <img src="Oikolex3.ico" alt="logo" id="logo" />
                        <div className='brand-text'>
                            Oikolex
                        </div>
                    </Link>
                    <ul className="navbar-nav">
                        <Link to="/Form" className="nav-link">
                            Form
                        </Link>
                    </ul>
                </div>
                {/* <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded={isDropdownOpen}
                    aria-label="Toggle navigation"
                    onClick={toggleDropdown}
                >
                    <span className="navbar-toggler-icon"></span>
                </button> */}
                {/* <div
                    className={`collapse navbar-collapse ${isDropdownOpen ? 'show' : ''}`}
                    id="navbarNavDropdown"
                > */}
                    <div className="right-nav">
                        <div className="navbar-reduce">
                        <Dropdown show={isDropdownOpen} onToggle={toggleDropdown}>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                <span className="navbar-toggler-icon"></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    as={Link}
                                    to={{
                                        pathname: '/Yay',
                                        search: `?userUid=${encodeURIComponent(user.currentUser?.uid)}`,
                                    }}
                                    className="nav-link"
                                >
                                    Support
                                </Dropdown.Item>

                                <Dropdown.Item
                                    as={Link}
                                    to={{
                                        pathname: '/Profile',
                                        search: `?userUid=${encodeURIComponent(user.currentUser?.uid)}`,
                                    }}
                                    className="nav-link"
                                >
                                    {user.currentUser ? 'Profile' : ''}
                                </Dropdown.Item>

                                <Dropdown.Item className="nav-link">
                                    {user.currentUser ? <SignOut user={user} /> : <SignInUp />}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        </div>
                        <div className="right-nav">
                            <div className="navbar-unreduced">
                                <ul className='navbar-nav'>
                                    <Link to={{
                                        pathname: "/Yay",
                                        search: `?userUid=${encodeURIComponent(user.currentUser?.uid)}`,
                                    }}
                                        className="nav-link"
                                    >
                                        Support
                                    </Link>
                                </ul>
                                <ul className="navbar-nav">
                                    {user.currentUser ?
                                    <Link to={{
                                        pathname: "/Profile",
                                        search: `?userUid=${encodeURIComponent(user.currentUser?.uid)}`,
                                    }}
                                        className="nav-link"
                                    >
                                        Profile
                                    </Link>: ""
                                    }
                                </ul>
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        {user.currentUser ? <SignOut className="nav-link" user = {user}/> : <SignInUp className="nav-link" />}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </nav>
        </>
    );
}
