import React, {useState} from "react";
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Sign.css';

export default function SignInUp() {
  const [showSignIn, setShowSignIn] = useState(false);

  const toggleForm = () => {
      setShowSignIn(!showSignIn);
      console.log(showSignIn);
  };
    
  return(
    <Link to={{
        pathname: "/Login",
    }}
    >
      <Button variant="outline-dark" onClick={toggleForm} id="button-center">
        Sign In
      </Button>
    </Link>
  )
}