import React, { useEffect, useState } from 'react';
import './Loading2.css';  // External CSS file

export default function Loading2({children}) {
    return(
        <div className="loading-container">
            <div className="loading-gradient">
            </div>
            {children}
        </div>
    )
}
