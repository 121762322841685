import React, { useState, useEffect } from "react";
import SignIn from "../Auth/SignIn";
import SignUp from "../Auth/SignUp";
import { applyActionCode, getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import {auth} from '../FirebaseConfig';
import { useNavigate, useLocation } from "react-router-dom";
import '../Auth/Sign.css'; // Ensure CSS has the background styles
import './Login.css'; // Ensure CSS has the background styles
import VerifyEmailMsg from "../Components/VerifyEmailMsg";

function ResetPassword({oobCode}) {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('The passwords do not match.');
      return;
    }

    try {
      await firebase.auth().confirmPasswordReset(oobCode, newPassword);
      setSuccess('Your password has been reset successfully. You can now sign in with your new password.');
      // Redirect user to the sign-in page after a delay or on user acknowledgment
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="reset-password-container">
      <h2>Reset Your Password</h2>
      <form onSubmit={handleResetPassword}>
        <div className="form-group">
          <label htmlFor="newPassword">New Password</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm New Password</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Reset Password</button>
      </form>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}
    </div>
  );
}

function VerifyEmail({oobCode}) {
  const [verified, setVerified] = useState(false);
  const navigate = useNavigate();
  const handleVerifyEmail = async (oobCode) => {
    try {
      // Apply the email verification action code
      await applyActionCode(auth, oobCode);

      // Optionally reload the user's data to reflect emailVerified status
      await auth.currentUser.reload();

      if (auth.currentUser.emailVerified) {
        console.log('Email successfully verified');
        setVerified(true);
        navigate('/home'); // Navigate to a success page or redirect user
      } else {
        console.error('Email verification failed');
        setVerified(false);
      }
    } catch (error) {
      console.error('Error verifying email:', error.message);
    }
  };
  return(
    <div className="verify-email-container">
      <h2>Click to Verify Your Email</h2>
      <button  className="verify-button" onClick={() => handleVerifyEmail(oobCode)}>Verify Email</button>
      {verified && <p>Thank YouYour email has been verified.</p>}
    </div>
  )
}

export default function Login({ user }) {
  const [showSignIn, setShowSignIn] = useState(true);
  const [isCardFlipped, setIsCardFlipped] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const mode = query.get('mode');
  const oobCode = query.get('oobCode');
  const navigate = useNavigate();

  const toggleForm = () => {
    setShowSignIn(!showSignIn);
  };

  const toggleCardFlip = () => {
    setIsCardFlipped(!isCardFlipped);
  };
  
    // Extract the full query string from the location
    const queryParams = new URLSearchParams(location.search);

    const addressPath = queryParams.get('address');
    // Get the `from` parameter
    const redirectPath = queryParams.get('from') || '/home';
  
    // Remove the 'from' parameter from the rest of the query params
    queryParams.delete('from');
  
    // Decode the redirect path
    const decodedRedirectPath = decodeURIComponent(redirectPath);
  
    // Replace commas with %2C in the redirect path
    const correctedRedirectPath = decodedRedirectPath.replace(/,/g, '%2C');
  
    // Check if the `from` value has an extra "?" in it
    let fullRedirectPath = "";
    if (correctedRedirectPath.includes("?")) {
      // If the `from` already includes a "?", just append the extra query params
      fullRedirectPath = `${correctedRedirectPath}&${queryParams.toString()}`;
    } else {
      // Otherwise, add the extra query params starting with "?"
      fullRedirectPath = queryParams.toString()
        ? `${correctedRedirectPath}?${queryParams.toString()}`
        : correctedRedirectPath;
    }

  useEffect(() => {
    if (user.currentUser) {
      if (user.currentUser.emailVerified) {
        //console.log("../" + fullRedirectPath);
        navigate("../" + fullRedirectPath, { replace: true }); // Redirect the user
      }
    }
  }, [user.currentUser, fullRedirectPath, navigate]);


  if(mode=="resetPassword"){
    return(
      <ResetPassword oobCode={oobCode}/>
    )
  }
  else if(mode=="verifyEmail"){
    return(
      <VerifyEmail oobCode={oobCode}/>
    )
  }

  return (
    <div className="login-background"> {/* Apply background style */}
      {user.currentUser ? (
        <div>
          {user.currentUser?.emailVerified ? (
            <div>Verified</div>
          ) : (
            <div className="verify">
              <VerifyEmailMsg user={user} />
            </div>
          )}
        </div>
      ) : (
        <div>
          {showSignIn && (
            <div className="sign-card-flip">
              <div className={`sign-card-flip-inner ${isCardFlipped ? 'flip' : ''}`}>
                <div className="sign-card-flip-back">
                  <SignUp
                    toggleFormSignUp={toggleForm}
                    toggleCardFlip={toggleCardFlip}
                    isCardFlipped={isCardFlipped}
                    user={user}
                  />
                </div>
                <div className="sign-card-flip-front">
                  <SignIn
                    toggleFormSignIn={toggleForm}
                    toggleCardFlip={toggleCardFlip}
                    isCardFlipped={isCardFlipped}
                    redirectPath={fullRedirectPath}
                    user={user}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
